const BASE_URL = "https://united-api.mtechnolab.com/api";

// Fetch products from the API
export const fetchProducts = async () => {
  try {
    const response = await fetch(`${BASE_URL}/show-products-web`, {
      method: "GET",
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Failed to fetch products:", error);
    return null;
  }
};

// Get products using fetchProducts
export const getProducts = async () => {
  const products = await fetchProducts();
  console.log(products);
  return products;
};
