import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useParams } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import { addToCart } from "../../store/actions/action";
import ShopProduct from "../../components/ShopProduct";
import Logo from "../../images/logo-2.png";
import Projects from "../../api/projects";
import { getProducts } from "../../api"; // Adjust the API call function as needed

const ProjectSinglePage = () => {
  const { slug } = useParams(); // Get slug from URL params
  const [products, setProducts] = useState([]); // State to hold API products
  const [filteredProducts, setFilteredProducts] = useState([]); // State for filtered products
  const [loading, setLoading] = useState(true); // Loading state

  // Find the current project details from Projects
  const ProjectSingle = Projects.find((item) => item.slug === slug);

  // Add product to cart handler
  const addToCartProduct = (product, qty = 1) => {
    addToCart(product, qty);
  };

  // Fetch products from API
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await getProducts(); // API call to get products
        setProducts(response);

        // Filter products based on slug
        const filtered = response.filter(
          (product) => product.slug.toLowerCase() === slug
        );
        setFilteredProducts(filtered);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [slug]);

  return (
    <Fragment>
      <Navbar Logo={Logo} />
      {ProjectSingle && (
        <PageTitle pageTitle={ProjectSingle.title} pagesub="Project" />
      )}
      <section className="wpo-project-single-area section-padding pt-3">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-12">
              <div className="wpo-project-single-wrap">
                <div className="wpo-project-single-item">
                  {ProjectSingle && (
                    <div className="row align-items-center">
                      <div className="col-lg-7">
                        <div className="wpo-project-single-title">
                          <h3>{ProjectSingle.title}</h3>
                        </div>
                        <p>{ProjectSingle.description.first}</p>
                        <p>{ProjectSingle.description.second}</p>
                      </div>
                      <div className="col-lg-5">
                        <div className="wpo-project-single-content-des-right">
                          <ul>
                            <li>
                              Location :<span>{ProjectSingle.location}</span>
                            </li>
                            <li>
                              Client :<span>wpOceans</span>
                            </li>
                            <li>
                              Architect :<span>{ProjectSingle.architect}</span>
                            </li>
                            <li>
                              Project Type :<span>{ProjectSingle.type}</span>
                            </li>
                            <li>
                              Duration :<span>{ProjectSingle.duration}</span>
                            </li>
                            <li>
                              Completion :<span>15 Dec 2024</span>
                            </li>
                            <li>
                              Share :<span>Industrial, Business</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-lg-12 px-0">
                    {loading ? (
                      <div>Loading products...</div>
                    ) : (
                      <ShopProduct
                        addToCartProduct={addToCartProduct}
                        products={filteredProducts}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default ProjectSinglePage;
