import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { addToCart } from "../../store/actions/action";
import Product from "./product";
import ProductTabs from "./alltab";
import Footer from "../../components/footer/Footer";
import Logo from "../../images/logo.png";
import { fetchProducts } from "../../api"; // Import `fetchProducts`

const ProductSinglePage = (props) => {
  const { id } = useParams(); // Use `id` from params
  const { addToCart } = props;

  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch products and filter the specific product by ID
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const products = await fetchProducts(); // Fetch all products
        const foundProduct = products.find(
          (product) => product.id === parseInt(id, 10) // Match by `id`
        );
        setProduct(foundProduct || null); // Set the product or null if not found
      } catch (error) {
        console.error("Error fetching product:", error);
        setProduct(null);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  return (
    <Fragment>
      <Navbar Logo={Logo} />
      <PageTitle pageTitle={"Product Details"} pagesub={"Product Details"} />
      <section className="wpo-shop-single-section section-padding">
        <div className="container">
          {loading ? (
            <p>Loading product details...</p>
          ) : product ? (
            <>
              <Product item={product} addToCart={addToCart} />
              <ProductTabs item={product} />
            </>
          ) : (
            <p>Product not found.</p>
          )}
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    products: state.data.products,
  };
};

export default connect(mapStateToProps, { addToCart })(ProductSinglePage);
